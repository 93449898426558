var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"card",attrs:{"to":{
    name: 'NotableCommercesSingle',
    params: {
      id: _vm.service.id,
      slug: _vm.service.slug,
      title: _vm.service.title,
    },
  }}},[(_vm.service.image)?_c('div',[_c('card-image',{attrs:{"image":_vm.service.image.mediumUrl}})],1):_c('div',[_c('card-image-not-found',{attrs:{"iconSize":"2x"}})],1),_c('div',{staticClass:"card-body"},[_c('h3',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.service.name))]),(_vm.service.summary)?_c('p',{staticClass:"card-text"},[_vm._v(" "+_vm._s(_vm.service.summary)+" ")]):_vm._e(),_c('span',{staticClass:"card-link"},[_vm._v("ver más")])])])}
var staticRenderFns = []

export { render, staticRenderFns }