var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{class:_vm.event.frame
      ? 'card event-related-card card-is-frame'
      : 'card event-related-card',attrs:{"to":{
    name: 'Event',
    params: {
      id: _vm.event.id,
      slug: _vm.event.slug,
      title: _vm.event.title,
    },
  }}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card-info"},[_c('div',{staticClass:"grid-container"},[_c('div',{staticClass:"card-day"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.event.start_date,"DD"))+" ")]),_c('div',{staticClass:"card-month"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.event.start_date,"MMM"))+" ")]),_c('img',{staticClass:"card-image",attrs:{"src":_vm.event.image.smallUrl,"alt":""}})])])]),_c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"card-body"},[_c('div',[_c('h4',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.event.title))]),_c('div',{staticClass:"card-date"},[_c('div',{staticClass:"date-item"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"calendar-alt"}}),_vm._v(_vm._s(_vm._f("moment")(_vm.event.start_date,"dddd"))+" ")],1),_c('div',{staticClass:"date-item"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"clock"}}),_vm._v(_vm._s(_vm.event.start_time.slice(0, 5))+"hs ")],1),_c('div',{staticClass:"date-item"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"map-marker-alt"}}),_vm._v(_vm._s(_vm.event.place.organization)+" ")],1)]),(_vm.event.frame)?_c('div',{staticClass:"card-frame"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"flag"}}),_vm._v(_vm._s(_vm.event.frame.title)+" ")],1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }