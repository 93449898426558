var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('b-carousel',{attrs:{"id":"slide-home","interval":4000,"controls":"","indicators":""},on:{"sliding-start":_vm.onSlideStart,"sliding-end":_vm.onSlideEnd},model:{value:(_vm.slide),callback:function ($$v) {_vm.slide=$$v},expression:"slide"}},_vm._l((_vm.events),function(event,index){return _c('b-carousel-slide',{key:index,scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"caruosel-image",attrs:{"src":event.image.largeUrl,"alt":"image slot"}}),_c('div',{staticClass:"carousel-caption"},[_c('h1',[_vm._v(_vm._s(event.title))]),_c('handle-date',{staticClass:"date",attrs:{"start":event.calendars[0].start_date,"end":event.calendars[0].end_date}}),(event.is_frame)?_c('div',[_c('b-link',{staticClass:"btn btn-outline-light",attrs:{"to":'/eventos/filter?f=' + event.id + '&' + event.slug}},[_vm._v(" ver eventos ")])],1):_c('div',[_c('b-link',{staticClass:"btn btn-sm btn-outline-light text-uppercase",attrs:{"to":{
                name: 'Event',
                params: {
                  id: event.id,
                  slug: event.slug,
                  title: event.title,
                },
              }}},[_vm._v(" ver más ")])],1)],1)]},proxy:true}],null,true)})}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }