var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"data"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',[_vm._v("+Info del Evento")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"calendar-alt"}})],1),_c('div',{staticClass:"info"},[_c('h6',[_vm._v("fecha")]),_c('p',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.event.start_date,"ddd D [de] MMMM YYYY"))+" ")])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"clock"}})],1),_c('div',{staticClass:"info"},[_c('h6',[_vm._v("hora")]),_c('p',[_vm._v(_vm._s(_vm.event.start_time.slice(0, 5))+" hs")])])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"flag"}})],1),_c('div',{staticClass:"info"},[_c('h6',[_vm._v("donde")]),_c('router-link',{attrs:{"to":{
                  name: 'Service',
                  params: {
                    id: _vm.event.place.organization_id,
                    slug: _vm.event.place.slug,
                    title: _vm.event.place.organization,
                  },
                }}},[_vm._v(" "+_vm._s(_vm.event.place.organization)+" ")])],1)])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"icon"},[_c('font-awesome-icon',{staticClass:"card-icon",attrs:{"icon":"map-marker-alt"}})],1),_c('div',{staticClass:"info"},[_c('h6',[_vm._v("dirección")]),_c('p',[_c('span',[_vm._v(_vm._s(_vm.event.place.address))])])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }