var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.count !== 0)?_c('section',{staticClass:"list-of-events-hightlights"},[_c('div',{staticClass:"container"},[_c('h2',[_vm._v("Eventos Recomendados")]),_c('div',{staticClass:"row justify-content-center"},_vm._l((_vm.events),function(event,index){return _c('div',{key:index,staticClass:"col-md-6 col-lg-4"},[_c('div',{staticClass:"card"},[_c('b-link',{attrs:{"to":{
              name: 'Event',
              params: {
                id: event.id,
                slug: event.slug,
                title: event.title,
              },
            }}},[_c('img',{staticClass:"card-image",attrs:{"src":event.image.mediumUrl,"alt":""}})]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title text-uppercase"},[_vm._v(_vm._s(event.title))]),_c('div',{staticClass:"card-title"},[_c('handle-date',{staticClass:"card-date",attrs:{"start":event.calendars[0].start_date,"end":event.calendars[0].end_date}})],1),_c('b-link',{staticClass:"btn btn-sm btn-primary text-uppercase",attrs:{"to":{
                name: 'Event',
                params: {
                  id: event.id,
                  slug: event.slug,
                  title: event.title,
                },
              }}},[_vm._v(" ver más ")])],1)],1)])}),0)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }