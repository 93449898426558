var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-link',{staticClass:"event-card-hightlights text-decoration-none",attrs:{"to":{
    name: 'Event',
    params: {
      id: _vm.event.id,
      slug: _vm.event.slug,
      title: _vm.event.title,
    },
  }}},[_c('div',{staticClass:"card"},[_c('img',{staticClass:"card-image",attrs:{"src":_vm.event.image.mediumUrl,"alt":""}}),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title"},[_vm._v(_vm._s(_vm.event.title))]),_c('p',{staticClass:"card-date"},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.event.start_date,"dddd D [de] MMM YYYY"))+" ")]),(_vm.event.place)?_c('p',{staticClass:"card-place"},[_vm._v(" "+_vm._s(_vm.event.place.organization)+" ")]):_vm._e(),(_vm.event.is_frame)?_c('p',[_vm._v("es mframe")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }