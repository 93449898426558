<template>
  <div class="hero">
    <img
      class="hero-image"
      src="https://images.unsplash.com/photo-1543007630-9710e4a00a20?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1635&q=80"
      alt="hero"
    />
    <div class="hero-logo">
      <img src="/img/logo-comercios-notables.png" alt="logo" />
    </div>
  </div>
</template>

<script>
export default {
  name: "NotableCommercesHero",
};
</script>
